import './footer.scss'
import { ReactComponent as MailImg } from './../../assets/images/mail.svg'

function Footer() {
    return (
        <footer>
            <div className="social">
            <div className="social-title"><h2>Benimle İletişime Geç</h2></div>
            <div className="social-body">
                    <a className="github-box" href="https://www.instagram.com/erhansavaskan/" target="_blank">
    <p>Instagram</p>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png" alt="Instagram Icon" width="48" height="48" />
</a>

                    <a className="linkedin-box" href="https://www.linkedin.com/in/erhan-sava%C5%9Fkan-166028210/" target="_blank">
            <p>LinkedIn</p>
            <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M42 8.64706V39.3529C42 40.055 41.7211 40.7283 41.2247 41.2247C40.7283 41.7211 40.055 42 39.3529 42H8.64706C7.94502 42 7.27173 41.7211 6.77531 41.2247C6.27889 40.7283 6 40.055 6 39.3529V8.64706C6 7.94502 6.27889 7.27173 6.77531 6.77531C7.27173 6.27889 7.94502 6 8.64706 6H39.3529C40.055 6 40.7283 6.27889 41.2247 6.77531C41.7211 7.27173 42 7.94502 42 8.64706V8.64706ZM16.5882 19.7647H11.2941V36.7059H16.5882V19.7647ZM17.0647 13.9412C17.0675 13.5407 16.9914 13.1436 16.8407 12.7726C16.69 12.4016 16.4677 12.0638 16.1866 11.7787C15.9054 11.4936 15.5707 11.2666 15.2018 11.1108C14.8329 10.955 14.4369 10.8734 14.0365 10.8706H13.9412C13.1268 10.8706 12.3458 11.1941 11.7699 11.7699C11.1941 12.3458 10.8706 13.1268 10.8706 13.9412C10.8706 14.7555 11.1941 15.5366 11.7699 16.1124C12.3458 16.6883 13.1268 17.0118 13.9412 17.0118V17.0118C14.3417 17.0216 14.7402 16.9525 15.1139 16.8083C15.4877 16.664 15.8293 16.4476 16.1194 16.1713C16.4095 15.895 16.6423 15.5642 16.8045 15.1979C16.9667 14.8316 17.0551 14.437 17.0647 14.0365V13.9412ZM36.7059 26.4141C36.7059 21.3212 33.4659 19.3412 30.2471 19.3412C29.1932 19.2884 28.1438 19.5129 27.2037 19.9922C26.2637 20.4715 25.4657 21.189 24.8894 22.0729H24.7412V19.7647H19.7647V36.7059H25.0588V27.6953C24.9823 26.7725 25.273 25.8567 25.8677 25.1469C26.4625 24.4372 27.3133 23.9908 28.2353 23.9047H28.4365C30.12 23.9047 31.3694 24.9635 31.3694 27.6318V36.7059H36.6635L36.7059 26.4141Z" fill="#66add3"/>
</svg>
            </a>
            <a className="twitter-box" href="https://www.behance.net/erhansavaskan" target="_blank">
            <p>Behance</p>
                        <img src="https://cdn-icons-png.flaticon.com/512/2496/2496088.png" alt="Behance Icon" width="48" height="48" />
            </a>
                    <a className="schedule-box" href="https://api.whatsapp.com/send/?phone=%2B905310870473&text&type=phone_number&app_absent=0" target="_blank">
            <p>Whatsapp</p>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Icon" width="48" height="48" />
            </a>
            </div>
                <a className="social-mail" href="mailto:breaterx6@gmail.com" target="_blank">
               <div className="box">
                <p>Mail Gönder</p>
                <MailImg className="mail" />
               </div>
            </a>
            </div>
            <div className="footer">
                <p>Built from scratch with <span>💚</span> | &copy; Copyright 2024 | Erhansavaskan.com.tr | All Rights Reserved.</p>
                
        </div>
        </footer>
    )
}

export default Footer